<template>
  <div class="Logo">
    <div class="parent">
    <img class="image5" src="../assets/LogoAnimation/practiceLogoWeb_white-01.png"/> <!-- 982/867 -->
    <img class="image4" src="../assets/LogoAnimation/practiceLogoWeb_white-02.png" /> <!-- 867/867 -->
    <img class="image3" src="../assets/LogoAnimation/practiceLogoWeb_white-03.png" /> <!-- 745/745 -->
    <img class="image2" src="../assets/LogoAnimation/practiceLogoWeb_white-04.png" /> <!-- 633/633 -->
    <img class="image1" src="../assets/LogoAnimation/practiceLogoWeb_white-05.png" /> <!-- 508/508 -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* mobile dimentions, home PC below */
.parent {
    z-index: 1;
     position: relative;
    top: 0;
    left: 0;
  height:  calc(90vw);
  width:  calc(90vw);
  display: flex;
  justify-content: center;
  align-items: center;
}
.image5 {
  position: absolute;
  /* top: 0;
  left: 0; */
  height:  calc(89vw);
  width:  calc(89vw);
  -webkit-animation: rotating 4s linear infinite;
  -moz-animation: rotating 4s linear infinite;
  -ms-animation: rotating 4s linear infinite;
  -o-animation: rotating 4s linear infinite;
  animation: rotating 4s linear infinite;
}
.image4 {
  position: absolute;
  /* top: 34px;
  left: 34px; */
  height:  calc(80vw);
  width:  calc(80vw);
  -webkit-animation: rotating 6s linear infinite;
  -moz-animation: rotating 6s linear infinite;
  -ms-animation: rotating 6s linear infinite;
  -o-animation: rotating 6s linear infinite;
  animation: rotating 6s linear infinite;
}
.image3 {
  position: absolute;
  /* top: 118px;
  left: 118px; */
  height:  calc(68vw);
  width:  calc(68vw);
  -webkit-animation: rotating 8s linear infinite;
  -moz-animation: rotating 8s linear infinite;
  -ms-animation: rotating 8s linear infinite;
  -o-animation: rotating 8s linear infinite;
  animation: rotating 8s linear infinite;
}
.image2 {
  position: absolute;
  /* top: 174px;
  left: 174px; */
  height:  calc(58vw);
  width:  calc(58vw);
  -webkit-animation: rotating 4s linear infinite;
  -moz-animation: rotating 4s linear infinite;
  -ms-animation: rotating 4s linear infinite;
  -o-animation: rotating 4s linear infinite;
  animation: rotating 4s linear infinite;
}
.image1 {
  position: absolute;
  /* top: 237px;
  left: 237px; */
  height:  calc(46vw);
  width:  calc(46vw);
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
}


@media only screen and (orientation:landscape) {
  /* Fom home PC */
  .parent {

    height:  calc(90vh);
     width:  calc(90vh);
  }
  .image5 {
    height:  calc(89vh);
     width:  calc(89vh);
  }
  .image4 {
    height:  calc(80vh);
     width:  calc(80vh);
  }
  .image3 {
    height:  calc(68vh);
     width:  calc(68vh);
  }
  .image2 {
    height:  calc(58vh);
     width:  calc(58vh);
  }
  .image1 {
    height:  calc(46vh);
    width:  calc(46vh);
  }
}
</style>
