<template>
  <div class="nav">
    <ul class="socials">
      <li><span>
        <a href="https://practicepresents.bandcamp.com/">Bandcamp</a>  
      </span></li>
      <li><span>
        <a href="https://www.facebook.com/practicepresents">Facebook</a>
        </span></li>
      <li><span>
        <a href="https://www.instagram.com/practicepresents/">Instagram</a>
      </span></li> 
      <li><span>
        <a href="https://soundcloud.com/practicepresents">Soundcloud</a>
      </span></li> 
      <li><span>
        <a href="https://mixcloud.com/practicepresents">Mixcloud</a>
      </span></li> 
  
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Socials',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav {
  z-index: 2;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.socials { 
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  
  background: rgba(var(--red),var(--green),var(--blue),0.8);  
  border-radius:20px;
}
ul {  
  padding-inline-start: 0px;
  padding: 20px
}
.socials li {
  display: inline-block;
  font: 1em sans-serif;
}

.socials li a {
  font-size: 16pt;
  color: #777;
  text-decoration: inherit; /* no underline */
}

.socials li a:hover {
  color: white;
}

@media only screen and (orientation:landscape) {
  /* Fom home PC */

  .nav {
    position:absolute;
    bottom: 2%;
    left: 2%;
    justify-content: right;
  }
  

  .socials li {
    margin-top: 10px;
  }

  li:hover {
    color: #FFF;    
    transition: .2s ease;
  }

  li:hover span{
    background:rgba(150, 180, 200, .6);
    padding-left:-50%;
    width:110%;
    border-radius:10px 6px;
    -webkit-box-shadow:0 0 20px 12px  rgba(150, 180, 200, .6);
    -moz-box-shadow: 0 0 20px 12px  rgba(150, 180, 200, .6);
    box-shadow: 0 0 20px 12px  rgba(150, 180, 200, .6);
    transition: .2s ease;
  }
}
</style>
