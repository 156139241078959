<template>
   <Home> </Home>
</template>
<script>
import Home from "./views/Home"
export default {
  name: 'App',
  components: {
    Home
  },   
}
</script>

<style>

:root {
  /* theme color variables to use in RGB declarations */
  --red: 3;
  --green: 4;
  --blue: 5;
  /*the threshold at which colors are considered "light". 
Range: decimals from 0 to 1,
recommended 0.5 - 0.6*/
  --threshold: 0.5;
  /*the threshold at which a darker border will be applied.
Range: decimals from 0 to 1,
recommended 0.8+*/
  --border-threshold: 0.8;
}

body { 
  background: rgb(var(--red),var(--green),var(--blue));
  display: flex;
  justify-content: center; 
  align-items: center; 
  width:calc(100vw);
  height:calc(100vh);
   overflow:hidden; 
  margin: 0px;
  }

@media only screen and (orientation:landscape) {
  /* Fom home PC */ 
  body { 
    padding-top: calc(2vh);
    justify-content: center; 
    align-items: baseline; 
    
  }
}
</style>
