<template>
  <Socials></Socials>
  <Logo> </Logo>
</template>


<script>
// @ is an alias to /src
import Logo from '../components/Logo'
import Socials from '../components/Socials'
export default {
  name: 'Home',
  components: {
    Logo,
    Socials
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
